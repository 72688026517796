$(document).ready(function() {
    isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;

    }

    function checkMobile() {
        if (isMobile) {
            $("body").addClass("is--mobile");
            $("body").removeClass("is--desktop");
        } else {
            $("body").removeClass("is--mobile");
            $("body").addClass("is--desktop");
        }
    }
    checkMobile();

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }


    let swiperScript = 0;

    // if ($(".swiper").length > 0) {
    //     console.log(isInViewport($(".swiper")[0]));
    //     if (isInViewport($(".swiper")[0]) && swiperScript == 0) {

    //         swiperScript = 1;
    //         $("head").append('<link rel="stylesheet" href="css/swiper.min.css">');
    //         $("body").append("<script src='js/swiper.js'></script>");
    //         $("body").append("<script src='js/out-swiperInit.js'></script>");

    //     }
    // }
    window.addEventListener("scroll", function(e) {

        if ($(".header").length) {
            var navbar = $(".header");
            var sticky = navbar[0].offsetTop;
            var st;
            var lastScrollTop = 0;

            function addFixedMenu() {

                if (window.pageYOffset >= navbar.height() + 300) {
                    navbar.addClass("fixed-header-scroll");
                    $("body").addClass("menu-is-fixed");

                } else {
                    navbar.removeClass("fixed-header-scroll");
                    $("body").removeClass("menu-is-fixed");
                }
            }
            if ($("body").height() > ($(window).height() + 400)) {
                st = $(this).scrollTop();
                // $("#menu-shadow").click();
                addFixedMenu();
                lastScrollTop = st;
            };

            // if ($(".swiper").length > 0) {
            //     console.log(isInViewport($(".swiper")[0]));
            //     if (isInViewport($(".swiper")[0]) && swiperScript == 0) {

            //         swiperScript = 1;
            //         $("head").append('<link rel="stylesheet" href="css/swiper.min.css">');
            //         $("body").append("<script src='js/swiper.js'></script>");
            //         $("body").append("<script src='js/out-swiperInit.js'></script>");

            //     }
            // }
        }
    });

    $(".nav-toggle").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass("open");
        if ($(this).hasClass("open")) {
            $('.body').addClass("menu-open");
            $(".nav").slideDown();
        } else {
            $(".nav ul.open").slideUp();
            $(".nav").slideUp();
            $('.body').removeClass("menu-open");
        }
        e.preventDefault();
        e.stopPropagation();
    })


    if (window.ontouchstart !== undefined) {
        $("body").addClass("menu-mobile");

        $(".menu__item--has-children>a").click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(this).siblings("ul").hasClass("open")) {
                $(this).siblings("ul").slideUp().addClass("open");
            } else {
                $(".nav ul.open").slideUp().removeClass("open");
                $(this).siblings("ul").slideDown().addClass("open");
            }
        })

    } else {
        $(".menu__item--has-children>a").click(function(e) {
            e.preventDefault();
            e.stopPropagation();
        });
        $(".menu__item--has-children").hover(function() {
            // $(this).children("ul").css("display", "block");
            $(this).children("ul").addClass("open");
        }, function() {
            $(this).children("ul").removeClass("open");
            // $(this).children("ul").css("display", "none");
        })
    }


    let startCalendar = 0;
    let startCalendar0 = 0;
    let startCalendar1 = 0;


    $("#askus-range").click(function() {
        if ($(this).hasClass("isopen")) {
            $(".daterangepicker").hide();

        }
        console.log($(this).hasClass("isopen"));
        $(this).toggleClass("isopen");
    });
    $("#askus-range--1").click(function() {
        if ($(this).hasClass("isopen")) {
            $(".daterangepicker").hide();
        }
        console.log($(this).hasClass("isopen"));
        $(this).toggleClass("isopen");
    });

    // $("#askus-range").click(function() {
    //     if (startCalendar == 0) {
    //         startCalendar = 1;
    //         $("head").append('<link rel="stylesheet" href="css/datarangepicker.min.css">');
    //         $("head").append("<script src='js/moment.min.js'></script>");
    //         $("head").append("<script src='js/daterangepicker.js'></script>");
    //     }
    //     if (startCalendar0 == 0) {
    //         startCalendar0 = 1;
    //         $("body").append("<script src='js/mobiscroll.init.js'></script>");
    //         setTimeout(() => {
    //             $("#askus-range").click();
    //         }, 300);

    //     }


    // });
    //  $("#askus-range--1").click(function() {
    //     console.log(startCalendar1);
    //     if (startCalendar == 0) {
    //         startCalendar = 1;
    //         $("head").append('<link rel="stylesheet" href="css/datarangepicker.min.css">');
    //         $("head").append("<script src='js/moment.min.js'></script>");
    //         $("head").append("<script src='js/daterangepicker.js'></script>");
    //     }
    //     if (startCalendar1 == 0) {
    //         startCalendar1 = 1;
    //         $("body").append("<script src='js/mobiscroll.init--1.js'></script>");
    //         setTimeout(() => {
    //             $("#askus-range--1").click();
    //         }, 300);
    //     }

    // });


    function accordion(accordion) {
        let accordionContent = accordion.querySelector(".accordion__content")
        let accordionBtn = accordion.querySelector(".accordion__btn");


        accordionBtn.addEventListener("click", function(e) {
            e.preventDefault();
            console.log(this);
            if (accordionBtn.classList.contains("active")) {
                this.classList.remove("active");
                this.setAttribute('aria-expanded', false);
                accordionContent.classList.remove("active");
                accordionContent.classList.remove("open");
                accordionContent.setAttribute('aria-hidden', true);
                accordionContent.style.maxHeight = null;
                accordion.classList.remove("active");
            } else {
                this.classList.add("active");
                this.setAttribute('aria-expanded', true);
                accordion.classList.add("active");
                accordionContent.setAttribute('aria-hidden', false);
                accordionContent.classList.add("active");
                accordionContent.classList.add("open");
                accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
            }
        })
    }

    Array.prototype.map.call(document.querySelectorAll(".accordion"), function(item) { accordion(item) });



    //swiper slder

    (function() {
        /* Opening modal window function */
        function openModal() {
            /* Get trigger element */
            var modalTrigger = document.getElementsByClassName('jsModalTrigger');

            /* Set onclick event handler for all trigger elements */
            for (var i = 0; i < modalTrigger.length; i++) {

                modalTrigger[i].onclick = function(e) {
                    e.preventDefault();
                    var target = this.getAttribute('data-href');
                    var modalWindow = document.getElementById(target);

                    modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
                }
            }
        }

        function closeModal() {
            /* Get close button */
            var closeButton = document.getElementsByClassName('jsModalClose');
            var closeOverlay = document.getElementsByClassName('jsOverlay');

            /* Set onclick event handler for close buttons */
            for (var i = 0; i < closeButton.length; i++) {
                closeButton[i].onclick = function() {
                    var modalWindow = this.parentNode.parentNode;

                    modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                }
            }

            /* Set onclick event handler for modal overlay */
            for (var i = 0; i < closeOverlay.length; i++) {
                closeOverlay[i].onclick = function() {
                    var modalWindow = this.parentNode;

                    modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                }
            }

        }

        /* Handling domready event IE9+ */
        function ready(fn) {
            if (document.readyState != 'loading') {
                fn();
            } else {
                document.addEventListener('DOMContentLoaded', fn);
            }
        }

        /* Triggering modal window function after dom ready */
        ready(openModal);
        ready(closeModal);



    }());





});






var http_request = false;

/*
data - dane do wysłania
callbackF - funkcja zwrotna
itemEl - elemnt na kórym wywołujemy funkcje zwrotną /opcjonalny
*/
function makeRequest(url = '/a/', method = 'POST', data, callbackF, itemEl) {

    http_request = false;

    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            http_request.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {}
        }
    }

    if (!http_request) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }

    http_request.onreadystatechange = function() { alertContents(http_request, callbackF, itemEl) };
    http_request.open(method, url, true);
    http_request.send(data);
}

function alertContents(http_request, functionName, itemEl) {
    try {
        if (http_request.readyState == 4) {
            if (http_request.status == 200) {
                console.log(itemEl);
                console.log(http_request.responseText);

                functionName(http_request.responseText, itemEl);


            } else {

                alert('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {
        // console.log('Złapany wyjątek: ' + e.description);
    }
}




//Newsletter: uzupełnienie wartości inputa w modalu

if (document.getElementById("newsletterBtn")) {
    function newsletterCallbackFunction(data) {
        document.getElementById("checbox-ajax").innerText = data;

    }

    document.getElementById("newsletterBtn").addEventListener("click", function() {
        document.getElementById("newsletterEmailModal").value = document.getElementById("newsletterEmail").value;
        if (!this.classList.contains("j2--click")) {
            this.classList.add("j2--click");
            data = new FormData();
            let token = document.querySelector("[name='__csrf__token__']").value;
            data.set('__csrf__token__', token);
            makeRequest('/a/?a=newsletter-zgoda', "GET", data, newsletterCallbackFunction);

        }

    })


    document.getElementById("newsletterEmail").addEventListener("keypress", function(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            document.getElementById("newsletterBtn").click();
        }
    })




    if (document.querySelector('.bigP__wrapperSlider')) {
        let bigPItems = document.querySelectorAll(".bigP__wrapperSlider img");
        let bigPItemsT = document.querySelectorAll(".bigP__title");
        let bigPItemsLength = bigPItems.length;
        let bigPItemActive = 0;


        if (bigPItems.length > 1) {
            setInterval(function() {
                bigPItems[bigPItemActive].classList.remove("active");
                bigPItemsT[bigPItemActive].classList.remove("active");
                if (bigPItemActive == bigPItemsLength - 1) {
                    bigPItemActive = 0;
                } else {
                    bigPItemActive++;
                }
                bigPItems[bigPItemActive].classList.add("active");
                bigPItemsT[bigPItemActive].classList.add("active");

            }, 10000)
        }
    }





}